<template lang="pug">
router-view
contacts-line
</template>

<script>
import { getUser } from '../assets/scripts/API'
import ContactsLine from '../components/_layout/TheContactsLine'

export default {
  name: 'UserPage',
  components: { ContactsLine },
  async beforeRouteEnter (to, from, next) {
    const user = await getUser()
    if (!user.is_auth) return next({ name: 'Login' })
    next()
  },
  async beforeRouteUpdate (to, from, next) {
    const user = await getUser()
    if (!user.is_auth) return next({ name: 'Login' })
    next()
  }
}
</script>
